import {
  FILTER_DATE,
  FILTER_FULL_SALE_TYPE,
  FILTER_PRICE,
  FILTER_SIZE,
  FILTER_TYPE_CHECKBOXES,
  FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_MULTI_RANGE,
  FILTER_UNIT_PRICE
} from "@/utils/filter";
import { noSelectClass } from "@/utils/user";
import { useEffect, useState } from "react";
import Sidebar from "../common/Sidebar";
import Loader from "../common/Loader";
import { convertDisplayDateMsec, dateStrToMsec, formatShortDate } from "@/utils/time";
import { getBlueHue } from "@/utils/colors";
import { NA } from "@/utils/convert";
import { Link } from "react-router-dom";
import MainChart from "./MainChart";

const FILTER_DATA_OPTIONS = [
  FILTER_DATE,
  FILTER_FULL_SALE_TYPE,
  FILTER_PRICE,
  FILTER_UNIT_PRICE,
  FILTER_SIZE,
];

const GRADIENT_OPTIONS = [
  {
    label: 'Price per area',
    value: 'Psf',
  },
  {
    label: 'Price',
    value: 'Price',
  },
  {
    label: 'Unit Size',
    value: 'Area',
  },
  {
    label: 'Recent Transactions',
    value: 'Date',
    // reverseColor: true,
  },
];

const ZOOM_OPTIONS = [
  { class: 'sm', size: '10' },
  { class: 'md', size: '10' },
  { class: 'lg', size: '12' },
  { class: 'xl', size: '14' },
  { class: 'xxl', size: '16' },
];

const getParamValue = (params, field) => !params ? null : params.get(field);

const initFilterFromParams = (params, options) => {
  const filters = {};
  options.forEach(option => {
    if (option.type === FILTER_TYPE_DATE_RANGE) {
      const startDate = getParamValue(params, option.field[0]);
      if (startDate) filters[option.field[0]] = new Date(parseInt(startDate));
      const endDate = getParamValue(params, option.field[1]);
      if (endDate) filters[option.field[1]] = new Date(parseInt(endDate));
    } else if (option.type === FILTER_TYPE_CHECKBOXES) {
      const values = getParamValue(params, option.field);
      if (values) {
        const filterValue = {};
        values.split(',').forEach(v => {
          filterValue[v] = true;
        });
        filters[option.field] = filterValue;
      }
    } else if (option.type === FILTER_TYPE_MULTI_RANGE) {
      const min = getParamValue(params, option.field[0]);
      if (min !== null) filters[option.field[0]] = min;
      const max = getParamValue(params, option.field[1]);
      if (max !== null) filters[option.field[1]] = max;
    }
  });
  return filters;
};

const UnitsView = ({
  id,
  data,
  user,
  isMaximized,
  mediaMatches
}) => {
  // filters
  const [filterSelected, setFilterSelected] = useState(initFilterFromParams(null, FILTER_DATA_OPTIONS));
  const [appliedFilter, setAppliedFilter] = useState(initFilterFromParams(null, FILTER_DATA_OPTIONS));
  const [defaultOrder, setDefaultOrder] = useState(true);

  const [dataLoading, setDataLoading] = useState(true);
  const [results, setResults] = useState({});
  const [resultsMeta, setResultsMeta] = useState({});
  const [tableData, setTableData] = useState(null);
  const [blocks, setBlocks] = useState(null);
  const [displayBlock, setDisplayBlock] = useState(null);
  const [gradient, setGradient] = useState(GRADIENT_OPTIONS[0].value);
  const [zoom, setZoom] = useState(ZOOM_OPTIONS.length - 4);

  const [cellDetailsLoading, setCellDetailsLoading] = useState(false);
  const [cellDetails, setCellDetails] = useState(null);

  const getBlockUnitFromAddress = (address) => {
    // check for unit number in address and handle all units within a block or street number
    const unitNumberMatch = address.match(/#\d{2}-\d{2}/);
    if (unitNumberMatch) {
      const unitNumber = unitNumberMatch[0];
      const blockStreet = address.split(unitNumber)[0].trim();
      const comps = unitNumber.split('-');
      const floor = parseInt(comps[0].replace('#', ''));
      const unit = parseInt(comps[1]);
      return {
        unit: unitNumber,
        block: blockStreet,
        floor,
        house: unit,
      };
    }

    // fallback if no unit number matched is to get the street one instead
    const blockMatch = address.match(/^\d+/);
    const blockStreet = blockMatch ? blockMatch[0] : '';
    return {
      unit: NA,
      block: !!blockStreet ? address : NA,
      floor: 1,
      house: 1,
    };
  };

  const saveResults = (results) => {
    if (!results) return;
    
    const blocks = new Set();
    let left = {};
    let right = {};
    let top = {};
    let bottom = {};
    let minPrice = Number.POSITIVE_INFINITY;
    let maxPrice = Number.NEGATIVE_INFINITY;
    let minPsf = Number.POSITIVE_INFINITY;
    let maxPsf = Number.NEGATIVE_INFINITY;
    let minArea = Number.POSITIVE_INFINITY;
    let maxArea = Number.NEGATIVE_INFINITY;
    let minDate = Number.POSITIVE_INFINITY;
    let maxDate = Number.NEGATIVE_INFINITY;

    // extract unit number
    // find the left/right-most units for rendering
    const updatedResults = results.map(r => {
      const blockUnit = getBlockUnitFromAddress(r['Address']);
      if (!blockUnit) return null;
      const unit = blockUnit.unit;
      const block = blockUnit.block;
      if (!(block in left)) left[block] = Number.POSITIVE_INFINITY;
      if (blockUnit.house < left[block]) left[block] = blockUnit.house;
      if (!(block in right)) right[block] = Number.NEGATIVE_INFINITY;
      if (blockUnit.house > right[block]) right[block] = blockUnit.house;
      if (!(block in top)) top[block] = Number.NEGATIVE_INFINITY;
      if (blockUnit.floor > top[block]) top[block] = blockUnit.floor;
      if (!(block in bottom)) bottom[block] = Number.POSITIVE_INFINITY;
      if (blockUnit.floor < bottom[block]) bottom[block] = blockUnit.floor;
      if (!blocks.has(block)) blocks.add(block);
      if (r['Transacted Price ($)'] < minPrice) minPrice = r['Transacted Price ($)'];
      if (r['Transacted Price ($)'] > maxPrice) maxPrice = r['Transacted Price ($)'];
      if (r['Area (SQFT)'] < minArea) minArea = r['Area (SQFT)'];
      if (r['Area (SQFT)'] > maxArea) maxArea = r['Area (SQFT)'];
      if (r['Unit Price ($ PSF)'] < minPsf) minPsf = r['Unit Price ($ PSF)'];
      if (r['Unit Price ($ PSF)'] > maxPsf) maxPsf = r['Unit Price ($ PSF)'];
      const ts = dateStrToMsec(r['Sale Date']);
      if (ts < minDate) minDate = ts;
      if (ts > maxDate) maxDate = ts;
      return {
        ...r,
        block,
        unit,
        floor: blockUnit.floor,
        house: blockUnit.house,
        ts,
      };
    }).filter(r => !!r);

    // if no proper units found, no results
    if (updatedResults.length === 0) return;

    // get list of blocks
    const blockList = Array.from(blocks)
      .sort((a, b) => {
        const matchA = a.match(/^(\d+)?(\D*)/);
        const matchB = b.match(/^(\d+)?(\D*)/);
        const numA = matchA[1] ? parseInt(matchA[1], 10) : null;
        const numB = matchB[1] ? parseInt(matchB[1], 10) : null;
        if (numA !== null && numB !== null) {
          if (numA !== numB) return numA - numB;
        } else if (numA !== null) {
          return -1;
        } else if (numB !== null) {
          return 1;
        }
        return a.localeCompare(b);
      })
      .map(block => ({ label: block, value: block }));

    // if no block found, no results
    if (blockList.length === 0) return;
    
    // generate the individual unit data
    const data = {};
    blockList.forEach(block => {
      data[block.value] = [];
      for (let i = bottom[block.value]; i <= top[block.value]; i += 1) {
        const row = Array(right[block.value] - left[block.value] + 1).fill(null);
        data[block.value].push(row);
      }
    });

    updatedResults.forEach(tx => {
      const floor = tx.floor;
      const house = tx.house;
      const block = tx.block;
      if (data[block][floor - bottom[block]][house - left[block]] === null) {
        data[block][floor - bottom[block]][house - left[block]] = {
          Date: tx['ts'],
          Price: tx['Transacted Price ($)'],
          Area: tx['Area (SQFT)'],
          Psf: tx['Unit Price ($ PSF)'],
          sale: tx['Type of Sale'],
          unit: tx['unit'],
          tx: [],
        };
      } else if (data[block][floor - bottom[block]][house - left[block]].ts < tx['ts']) {
        data[block][floor - bottom[block]][house - left[block]].ts = tx['ts'];
        data[block][floor - bottom[block]][house - left[block]].price = tx['Transacted Price ($)'];
        data[block][floor - bottom[block]][house - left[block]].area = tx['Area (SQFT)'];
        data[block][floor - bottom[block]][house - left[block]].unit_price = tx['Unit Price ($ PSF)'];
        data[block][floor - bottom[block]][house - left[block]].sale = tx['Type of Sale'];
      }
      data[block][floor - bottom[block]][house - left[block]].tx.push(tx);
    });

    // save results
    setBlocks(blockList);
    setDisplayBlock(blockList[0].value);
    setResults(data);
    setResultsMeta({
      left,
      right,
      top,
      bottom,
      minPrice,
      maxPrice,
      minPsf,
      maxPsf,
      minArea,
      maxArea,
      minDate,
      maxDate,
    });
  };

  useEffect(() => {
    if (data !== null && data !== undefined) {
      saveResults(data);
    }
  }, [data]);

  const selectCell = (unit) => {
    setCellDetailsLoading(true);
    setCellDetails({
      ...unit,
      gains: unit.tx?.length > 0
        ? unit.tx.map((tx, i) => {
            const gain = calculateAnnualized(i, unit.tx);
            return gain !== null ? {
              ts: tx['ts'],
              gain,  
            } : null
          })
        : []
    });
    setCellDetailsLoading(false);
  };

  const onFilterApply = () => {
    setAppliedFilter(filterSelected);
    renderTable(filterSelected, defaultOrder);

    if (document.getElementById('listingSidebar').classList.contains('show')) {
      document.getElementById('filter-close-button').click();
    }
  };

  const onFilterReset = () => {
    setFilterSelected({});
    setAppliedFilter({});
    renderTable({}, defaultOrder);

    if (document.getElementById('listingSidebar').classList.contains('show')) {
      document.getElementById('filter-close-button').click();
    }
  };

  const zoomIn = () => {
    setZoom(zoom - 1);
  };

  const zoomOut = () => {
    setZoom(zoom + 1);
  };

  const renderTable = (filterSelected, defaultOrder) => {
    if (results && Object.keys(results).length === 0) return;
    setDataLoading(true);

    // filter by block
    let filteredResults = results[displayBlock];
    
    // filter by selection
    filteredResults = filteredResults.map(row =>
      row.map(col => {
        if (!col) return null;
        const tx = col.tx.filter(t => {
          if (filterSelected.start_date && t['ts'] < filterSelected.start_date.getTime())
            return false;
          if (filterSelected.end_date && t['ts'] > filterSelected.end_date.getTime())
            return false;
          if (filterSelected.sale_type && Object.entries(filterSelected.sale_type).some(e => !!e[1]) && !filterSelected.sale_type[t['Type of Sale']])
            return false;
          if (filterSelected.min_price && t['Transacted Price ($)'] < parseInt(filterSelected.min_price))
            return false;
          if (filterSelected.max_price && t['Transacted Price ($)'] > parseInt(filterSelected.max_price))
            return false;
          if (filterSelected.min_unit_price && t['Unit Price ($ PSF)'] < parseFloat(filterSelected.min_unit_price))
            return false;
          if (filterSelected.max_unit_price && t['Unit Price ($ PSF)'] > parseFloat(filterSelected.max_unit_price))
            return false;
          if (filterSelected.min_size && t['Area (SQFT)'] < parseInt(filterSelected.min_size))
            return false;
          if (filterSelected.max_size && t['Area (SQFT)'] > parseInt(filterSelected.max_size))
            return false;
          return true;
        }).sort((a, b) => b['ts'] - a['ts']);
        if (tx.length === 0) return null;
        return {
          Date: tx[0]['ts'],
          Price: tx[0]['Transacted Price ($)'],
          Area: tx[0]['Area (SQFT)'],
          Psf: tx[0]['Unit Price ($ PSF)'],
          sale: tx[0]['Type of Sale'],
          unit: tx[0]['unit'],
          tx,
        };
      })  
    );

    // generate table
    const rows = [];
    const headerRow = [];
    headerRow.push(
      <th
        key="hdr-X"
        className={`header-cell block-cc text-${ZOOM_OPTIONS[zoom].size}`}
      >
        Unit →
        <br />
        <span
          className="cursor-pointer"
          onClick={() => setDefaultOrder(!defaultOrder)}
        >
          Storey {defaultOrder ? '↓' : '↑'}
        </span>
      </th>
    );
    for (let i = 0; i <= (resultsMeta.right[displayBlock] - resultsMeta.left[displayBlock]); i += 1) {
      headerRow.push(
        <th
          key={`hdr-${i}`}
          className={`header-cell block-tr text-${ZOOM_OPTIONS[zoom].size}`}
        >
          {resultsMeta.left[displayBlock] + i}
        </th>
      );
    }
    rows.push(headerRow);

    const reverseColor = !!GRADIENT_OPTIONS.find(o => o.value === gradient).reverseColor;

    if (defaultOrder) {
      filteredResults.reverse();
    }

    filteredResults.forEach((row, i) => {
      const cells = [];
      cells.push(
        <th
          key={`rX-${i}`}
          className={`header-cell block-lc text-${ZOOM_OPTIONS[zoom].size}`}
        >
          {defaultOrder ? (filteredResults.length - i + resultsMeta.bottom[displayBlock] - 1) : (i + resultsMeta.bottom[displayBlock])}
        </th>
      );
      row.forEach((col, j) => {
        cells.push(
          col === null
          ? <td
              key={`r${i}-${j}`}
              className={`data-cell data-cell-${ZOOM_OPTIONS[zoom].class} text-${ZOOM_OPTIONS[zoom].size} text-center`}
              style={{ background: 'white' }}
            >
              <span></span>
            </td>
          : <td
            key={`r${i}-${j}`}
            className={`data-cell data-cell-${ZOOM_OPTIONS[zoom].class} text-${ZOOM_OPTIONS[zoom].size} text-center cursor-pointer`}
            style={{
              background: getBlueHue(
                (col[gradient] - resultsMeta[`min${gradient}`]) / (resultsMeta[`max${gradient}`] - resultsMeta[`min${gradient}`]),
                reverseColor
              )
            }}
            data-bs-toggle="offcanvas"
            data-bs-target="#listingDetails"
            onClick={() => selectCell(col)}
          >
            {ZOOM_OPTIONS[zoom].class !== 'sm'
              && col.unit !== NA && <span className={`unit-text-${ZOOM_OPTIONS[zoom].size}`}>
                <strong>{col.unit}</strong>
                <br />
              </span>
            }
            
            {(ZOOM_OPTIONS[zoom].class !== 'sm' || gradient === 'Date')
              && <span className={`unit-text-${ZOOM_OPTIONS[zoom].size}`}>{convertDisplayDateMsec(col.Date)}<br /></span>
            }
            
            {(ZOOM_OPTIONS[zoom].class !== 'sm' || gradient === 'Area')
              &&<span className={`unit-text-${ZOOM_OPTIONS[zoom].size}`}>{col.Area.toLocaleString()} sft<br /></span>
            }
            
            {(ZOOM_OPTIONS[zoom].class !== 'sm' || gradient === 'Psf')
              && <span className={`unit-text-${ZOOM_OPTIONS[zoom].size}`}>${col.Psf.toLocaleString()} psf<br /></span>
            }

            {(ZOOM_OPTIONS[zoom].class !== 'sm' || gradient === 'Price')
              &&<span className={`unit-text-${ZOOM_OPTIONS[zoom].size}`}>${col.Price.toLocaleString()}</span>
            }
          </td>
        );
      });

      rows.push(<tr key={i}>{cells}</tr>);
    });
    
    setTableData(rows);
    setDataLoading(false);
  };

  useEffect(() => {
    renderTable(appliedFilter, defaultOrder);
  }, [results, zoom, gradient, displayBlock, defaultOrder]);

  const calculateAnnualized = (index, transactions) => {
    const total = transactions?.length;
    if (total > 1 && index !== (total - 1)) {
      const innerPrice = transactions[index + 1]['Transacted Price ($)'];
      const outerPrice = transactions[index]['Transacted Price ($)'];
      const innerDate = new Date(transactions[index + 1]['ts']);
      const outerDate = new Date(transactions[index]['ts']);
      let diff = (outerDate.getFullYear() - innerDate.getFullYear()) * 12;
      diff -= innerDate.getMonth();
      diff += outerDate.getMonth();
      const years = diff / 12;
      return (
        (outerPrice / innerPrice) **
        (1 / Math.max(1.0, years)) - 1
      ) * 100.0;
    }
    return null;
  };
  
  return (
    <section className={`${noSelectClass(user)} watermark`}>
      <div className="maxw">

        <div className="row y-gap-10 ml-0 mr-0 px-0">

          <div
            className="offcanvas offcanvas-start filter-bar-nopad mt-50"
            tabIndex="-1"
            id="listingSidebar"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title text-18" id="offcanvasLabel">
                Filter Data
              </h5>
              <button
                id="filter-close-button"
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>

            <div className="offcanvas-body">
              <aside className="sidebar y-gap-40 xl:d-block">
                <Sidebar
                  filters={filterSelected}
                  setFilters={setFilterSelected}
                  options={FILTER_DATA_OPTIONS}
                />
              </aside>
            </div>

            <div className="row ml-10 mr-10 mt-10">
              <div className="col-3">
                <button
                  className="button -dark-1 py-15 px-40 h-50 col-12 rounded-0 bg-red-1 text-white w-100"
                  onClick={onFilterReset}
                >
                  Reset
                </button>
              </div>
              <div className="col-9">
                <button
                  className="button -dark-1 py-15 px-40 h-full col-12 rounded-0 bg-blue-1 text-white w-100"
                  onClick={onFilterApply}
                >
                  Apply Filter
                </button>
              </div>
            </div>

          </div>

          <div
            className="offcanvas offcanvas-end filter-bar-nopad"
            tabIndex="-1"
            id="listingDetails"
          >
            <div className="offcanvas-header block-list-panel">
              <h5 className="offcanvas-title text-16" id="offcanvasLabel">
                {displayBlock} {cellDetails ? (
                  cellDetails.unit !== NA ? cellDetails.unit : id.split(',')[1]
                ) : ''}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>

            <div className="offcanvas-body watermark">
              <aside className="sidebar y-gap-20 xl:d-block pl-10 pb-20">
                {cellDetailsLoading
                  && <div className="loader-container">
                    <Loader />
                  </div>
                }
                {!cellDetailsLoading && cellDetails
                  && <>
                    <h2 className="text-15 lh-1 fw-500">Price Trend ($)</h2>
                    <MainChart
                      label="Price Trend ($)"
                      data={
                        cellDetails.tx.map(row => ({
                          x: row['ts'],
                          y: row['Transacted Price ($)'],
                        }))
                      }
                      tooltipTitle="Price ($)"
                      tooltipLabelCallback={
                        (context) => {
                          const date = new Date(context.raw.x);
                          const formattedDate = formatShortDate(date);
                          return `${formattedDate}: $${context.raw.y.toLocaleString()}`;
                        }
                      }
                      yAxisLabel="Price ($)"
                    />

                    <div className="pt-10"></div>

                    {cellDetails.gains.filter(g => g !== null).length > 0 && <>
                      <h2 className="text-15 lh-1 fw-500">Annualized Profit (%)</h2>
                      <MainChart
                        label="Annualized Profit (%)"
                        data={
                          cellDetails.gains.filter(g => g !== null).map(row => ({
                            x: row['ts'],
                            y: row['gain'],
                          }))
                        }
                        tooltipTitle="Annualized Profit (%)"
                        tooltipLabelCallback={
                          (context) => {
                            const date = new Date(context.raw.x);
                            const formattedDate = formatShortDate(date);
                            return `${formattedDate}: ${context.raw.y.toFixed(2)}%`;
                          }
                        }
                        yAxisLabel="Annualized Profit (%)"
                      />
                    </>}

                    {cellDetails.tx.length > 0 
                      && <h2 className="text-15 lh-1 fw-500">Transaction History</h2>
                    }
                    
                    {cellDetails.tx.map((row, i) => (
                      <div
                        className="px-10 py-5 bg-blue-2 text-12 text-blue-1 mr-20 mt-10 text-10"
                      >
                        <span className="text-14 fw-500">
                          {convertDisplayDateMsec(row['ts'])}
                        </span>
                        <br />
                        <span className="">
                          Type of Sale: {row['Type of Sale']}
                        </span>
                        <br />
                        <span className="">
                          Price: ${row['Transacted Price ($)'].toLocaleString()}
                        </span>
                        <br />
                        <span className="">
                          Price per area: ${row['Unit Price ($ PSF)'].toLocaleString()} psf
                        </span>
                        {cellDetails.gains.length > 0 && cellDetails.gains[i] !== null
                          && <>
                            <br />
                            <span className="">
                              Annualized Profit (%): {cellDetails.gains[i].gain.toFixed(2)}%
                            </span>
                          </>
                        }
                      </div>
                    ))}
                  </>
                }
              </aside>
            </div>
          </div>

          <div className="col-12 ml-0 mr-0 px-0" id="tx-table-view">
            {dataLoading
              && <div className="loader-container">
                <Loader />
              </div>
            }

            {!dataLoading && id && Object.keys(results).length > 0
              && <>
                
                <div className="d-flex justify-content-between align-items-center mb-10 filter-bar-nopad proj-map-spad noselect">
                  <div className="p-2 d-flex ml-0 mr-0 px-0">
                    <button
                      className="block-filter dropdown__button_sm button rounded-100 button -blue-1 bg-blue-1-05 text-12 text-blue-1 p-2 ml-0 mr-0"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#listingSidebar"
                    >
                      <i className="icon-menu text-14 mr-10" />
                      Filter Data
                    </button>
                  </div>

                  <Link
                    to={`/project/${encodeURIComponent(`${id}`)}`}
                    target="_blank"
                    className="fw-500 text-14 md:d-none cursor-point"
                    data-tooltip-id="unitsview-selection-tooltip"
                    data-tooltip-content={id.split(',')[1]}
                    data-tooltip-variant="dark"
                    data-tooltip-place="bottom"
                  >
                    {/* {id.split(',')[0]} */}
                  </Link>

                  <div className="p-2 d-flex filter-bar-nopad">
                    <div className="dropdown js-dropdown js-category-active p-2 filter-bar-nopad proj-map-spad">
                      <div
                        className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-15 py-10 text-12 lh-12"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="true"
                        aria-expanded="false"
                        data-bs-offset="0,10"
                        data-tooltip-id="unitsview-selection-tooltip"
                        data-tooltip-content="Set gradient: higher value will appear dark blue"
                        data-tooltip-variant="dark"
                        data-tooltip-place="top"
                      >
                        <span className="js-dropdown-title">
                          <span className="text-blue-1 fw-600"></span>
                          <span className="js-dropdown-grad">
                            Gradient: {GRADIENT_OPTIONS.find(o => o.value === gradient).label}
                          </span>
                        </span>
                        <i className="icon icon-chevron-sm-down text-7 ml-10" />
                      </div>
                      <div className="toggle-element -dropdown dropdown-menu">
                        <div className="text-13 y-gap-15 js-dropdown-list">
                          {GRADIENT_OPTIONS.map(option => (
                            <div key={option.value}>
                              <button
                                className={`d-block js-dropdown-link ${
                                  gradient === option.value ? "text-blue-1 " : ""
                                }`}
                                onClick={() => {
                                  setGradient(option.value);
                                  document.querySelector(".js-dropdown-grad").textContent = option.label;
                                }}
                              >
                                {option.label}
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    &nbsp;&nbsp;
                    <div className="dropdown js-dropdown js-category-active p-2 filter-bar-nopad proj-map-spad">
                      <div
                        className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-15 py-10 text-12 lh-12"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="true"
                        aria-expanded="false"
                        data-bs-offset="0,10"
                        data-tooltip-id="unitsview-selection-tooltip"
                        data-tooltip-content="Select block or street number"
                        data-tooltip-variant="dark"
                        data-tooltip-place="top"
                      >
                        <span className="js-dropdown-title">
                          <span className="text-blue-1 fw-600"></span>
                          <span className="js-dropdown-res">{
                            blocks.find(v => v.value === displayBlock).label
                          }</span>
                        </span>
                        <i className="icon icon-chevron-sm-down text-7 ml-10" />
                      </div>
                      <div className="toggle-element -dropdown dropdown-menu project-search-dropdown">
                        <div className="text-12 y-gap-15 js-dropdown-list">
                          {blocks.map(option => (
                            <div key={option.value}>
                              <button
                                className={`d-block js-dropdown-link ${
                                  displayBlock === option.value ? "text-blue-1 " : ""
                                }`}
                                onClick={() => {
                                  setDisplayBlock(option.value);
                                  document.querySelector(".js-dropdown-res").textContent = option.label;
                                }}
                              >
                                {option.label}
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="d-flex flex-row-reverse noselect">
                  <button
                    className="button -dark-1 py-15 px-10 h-20 rounded-100 bg-blue-1 text-white"
                    data-tooltip-id="unitsview-selection-tooltip"
                    data-tooltip-content="Zoom out"
                    data-tooltip-variant="dark"
                    data-tooltip-place="bottom"
                    onClick={zoomOut}
                    disabled={zoom === ZOOM_OPTIONS.length - 1}
                  >
                    <i className="icon-plus text-12" />
                  </button>
                  <button
                    className="button -dark-1 py-15 px-10 h-20 rounded-100 bg-blue-1 text-white mr-10"
                    data-tooltip-id="unitsview-selection-tooltip"
                    data-tooltip-content="Zoom in"
                    data-tooltip-variant="dark"
                    data-tooltip-place="bottom"
                    onClick={zoomIn}
                    disabled={zoom === 0}
                  >
                    <i className="icon-minus text-12" />
                  </button>
                </div>

                <div className="blocks-w d-flex justify-content-center noselect">
                  <div className={`blocks-container ${mediaMatches && !isMaximized ? 'blocks-h-m' : (
                    isMaximized ? 'blocks-h-max' : 'blocks-h'
                  )} mt-10`}>
                    <table>
                      <thead>
                        {tableData}
                      </thead>
                    </table>
                  </div>
                </div>
              </>
            }

          </div>
        </div>
      </div>
    </section>
  );
};

export default UnitsView;
